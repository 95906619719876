<template>
    <div>
		<e-select
			id="selectInput"
			track-by="id"
			label="label"
			v-model="input_text"
			:options="options"
			:required="required"
            :show-labels="false"
			:allow-empty="allow_empty"
		>
        </e-select>
    </div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
    import Navigation from '@/mixins/Navigation.js'
import ESelect from '../../../../groomy-root/src/components/Utils/ESelect.vue'

	export default {
  components: { ESelect },
		name: "SelectInput",
        mixins: [Navigation],
        props:[
            'placeholder',
            'z_id',
            'answer_given',
            'required',
            'options',
            'content', 
            'allow_empty'
        ],
        data() {
            return {
                input_text: '',
                events_tab: {
                    'inputs::resetChecked': this.resetCheck
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            init_component() {
                this.input_text = this.content
            },
            resetCheck() {
                this.input_text = ''
            }
        },
        watch: {
            'input_text' (val) {
                this.$emit('update:answer', { key: this.z_id, val: val, type: 'SelectInput' })
            }
        }
    }
</script>
